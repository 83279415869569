@use '~@sbt-web/ui/common/css/variables' as v;

.category-input-wrapper {
  position: relative;
  height: auto;
  overflow-y: hidden;
  border: var(--sbt-border-default);
  margin-bottom: var(--sbt-spacing-md);
  border-radius: var(--sbt-spacing-2xs);

  .category-input {
    border: 0;
    margin-bottom: 0;
    .input-inner {
      cursor: pointer;
      text-align: left;
      flex: 1;
    }

    .input-arrow {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.25s ease-out;
      &.input-arrow-rotate {
        transform: rotate(180deg);
      }
    }
  }

  .cat-item-list {
    font-size: v.$default_font_size;
    padding: 9px var(--sbt-spacing-sm);
    color: var(--sbt-primary-text-color);
    line-height: 24px;
    margin: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: left;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: var(--sbt-background-root);
    }
    &.bold {
      font-weight: 600;
    }
  }

  //Mobile implementation:
  @media (max-width: v.$tablet_max_width) {
    .desktop-category {
      display: none;
    }
    .mobile-category {
      display: block;
    }
    .title-cat-mobile-modal {
      font-size: v.$big1_font_size;
      color: var(--sbt-primary-text-color);
      padding: var(--sbt-spacing-md);
      line-height: 25px;
      font-weight: 600;
      display: block;
      text-align: center;
    }
    .list-mobile-wrapper {
      padding: var(--sbt-spacing-md) var(--sbt-spacing-md) var(--sbt-spacing-xl);
      width: 100%;
      .title-cat {
        display: block;
        font-weight: 600;
        color: var(--sbt-primary-text-color);
      }
      ul {
        padding: 0;
        margin: var(--sbt-spacing-md) 0;
      }
    }
  }

  //Desktop implementation:
  @media (min-width: v.$desktop_min_width) {
    .mobile-category {
      display: none;
    }

    .lt-allCat {
      display: block;
      width: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      transition: max-height 0.25s ease-in-out;
      margin: 0;
      padding: 0;
      max-height: 0;
      list-style-type: none;
      background: v.$white;
      border-radius: 0 0 var(--sbt-corner-md) var(--sbt-corner-md);

      &.lt-allCat-visible {
        max-height: 225px;
      }
    }
  }
}

.sx-inner {
  min-height: 140px;
  display: flex;
  flex-direction: column;
}

.modal-dx-inner {
  overflow: auto;
  position: relative;
  padding: var(--sbt-spacing-md) var(--sbt-spacing-md) var(--sbt-spacing-xl);
}

.form-group-01 {
  margin-bottom: var(--sbt-spacing-2xl);
}

.input-label {
  color: var(--sbt-primary-text-color);
  font-size: v.$secondary_font_size;
  line-height: 20px;
  margin: 0 0 6px;
  display: block;
  font-weight: 600;
}

.input {
  width: 100%;
  height: auto;
  border: var(--sbt-border-default);
  border-radius: 4px;
  overflow: hidden;
  appearance: none;
  transition: border-color 0.5s ease-out;
  display: flex;
  margin-bottom: v.$default_font_size;

  &:focus,
  &:hover {
    border-color: v.$neutralGrey3;
  }
  input {
    width: 100%;
  }
}

.input-icon {
  background-color: var(--sbt-background-default);
  display: inline-block;
  vertical-align: middle;
  padding: 7px;
}

.submit-button {
  color: v.$white;
  width: 100%;
  margin: 18px 0;
  padding: var(--sbt-layout-2xs);
  border-radius: var(--sbt-corner-md);
  text-align: center;
  font-size: v.$default_font_size;
  line-height: v.$default_font_size;
  font-weight: 600;
}

.modal {
  &.motori {
    .sx-inner {
      background-color: v.$blueMotoriL3;
    }

    :global(.close-x-modal) {
      fill: v.$blueMotori;
    }

    .submit-button {
      background-color: v.$blueMotori;

      &:hover {
        background-color: v.$blueMotoriL1;
      }
    }
  }

  &.lavoro {
    .sx-inner {
      background-color: v.$greenLavoroL3;
    }

    :global(.close-x-modal) {
      fill: v.$greenLavoro;
    }

    .submit-button {
      background-color: v.$greenLavoro;

      &:hover {
        background-color: v.$greenLavoroL1;
      }
    }
  }

  &.market {
    .sx-inner {
      background-color: v.$yellowMarketL3;
    }

    :global(.close-x-modal) {
      fill: v.$yellowMarket;
    }

    .submit-button {
      background-color: v.$yellowMarket;

      &:hover {
        background-color: v.$yellowMarketL1;
      }
    }
  }

  &.immobili {
    .sx-inner {
      background-color: v.$purpleImmobiliL3;
    }

    :global(.close-x-modal) {
      fill: v.$purpleImmobili;
    }

    .submit-button {
      background-color: v.$purpleImmobili;

      &:hover {
        background-color: v.$purpleImmobiliL1;
      }
    }
  }
}

@media (min-width: v.$desktop_min_width) {
  .sx-inner {
    width: 240px;
    flex: 0 1 240px;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: var(--sbt-spacing-xs) 0 0 var(--sbt-spacing-xs);
    padding: var(--sbt-spacing-2xl) var(--sbt-spacing-lg);
  }
  .modal-dx-inner {
    flex: auto;
    position: relative;
    padding: 68px 60px 50px 38px;
  }
}

@media (max-width: v.$tablet_max_width) {
  .sx-inner {
    height: 140px;
    padding: var(--sbt-spacing-xl);
    align-items: flex-start;
    justify-content: flex-end;
  }

  .modal-dx-inner {
    flex: 1 0 auto;
    padding: var(--sbt-spacing-2xl) var(--sbt-spacing-md) 0
      var(--sbt-spacing-xl);
    overflow: auto;
    position: relative;
  }
}

@media (max-width: v.$mobile_max_width) {
  .input-icon {
    height: 48px;
    width: 48px;
    padding: 12px;
  }
}
