@use '~@sbt-web/ui/common/css/variables';

.logo-mobile {
  display: none;
}

.mobile-search-header {
  margin-bottom: var(--sbt-spacing-sm);
  text-align: center;
}
@media (max-width: variables.$tablet_max_width) {
  .logo-mobile {
    display: block;
    text-align: center;
    padding: 0 var(--sbt-spacing-sm) var(--sbt-spacing-xl);
  }
}

@media (min-width: variables.$tablet_min_width) {
  .mobile-search-header {
    display: none;
  }
}
