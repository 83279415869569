@use '~@sbt-web/ui/common/css/variables';

.category-box {
  width: 100%;
  height: 80px;
  border-radius: var(--sbt-corner-lg);
  text-align: center;
  display: flex;
  gap: var(--sbt-spacing-xs);
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color ease-in-out 0.2s,
      border-color ease-in-out 0.2s;

  &:hover, &:focus {
    .category-box__icon-wrapper {
      border-color: var(--vertical-menu-color-accent);
    }
  }

  &__icon-wrapper {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--vertical-menu-color-backdrop);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    transition: border-color ease-in-out 0.2s;
  }
  &__icon {
    width: var(--sbt-icon-lg);
    height: var(--sbt-icon-lg);
  }
  &__label {
    color: var(--vertical-menu-color-accent);
    font-family: var(--sbt-font-family);
  }
}

.category-box-motori {
  --vertical-menu-color-accent: var(--sbt-accent-blue);
  --vertical-menu-color-backdrop: var(--sbt-backdrop-blue);
}
.category-box-market {
  --vertical-menu-color-accent: var(--sbt-accent-yellow);
  --vertical-menu-color-backdrop: var(--sbt-backdrop-yellow);
}
.category-box-immobili {
  --vertical-menu-color-accent: var(--sbt-accent-purple);
  --vertical-menu-color-backdrop: var(--sbt-backdrop-purple);
}
.category-box-lavoro {
  --vertical-menu-color-accent: var(--sbt-accent-green);
  --vertical-menu-color-backdrop: var(--sbt-backdrop-green);
}

@media (min-width: variables.$tablet_min_width) {
  .category-box {
    border: var(--sbt-border-default);

    &:hover, &:focus {
      background-color: var(--vertical-menu-color-backdrop);
      border-color: var(--vertical-menu-color-accent);
    }
    &__icon {
      transform: scale(0.85);
    }
    &__label {
      font-size: 20px;
    }
  }
}
@media (max-width: variables.$mobile_max_width) {
  .category-box {
    flex-direction: column;
    height: 83px;

    &__label {
      font-size: variables.$secondary_font_size;
    }
  }
}

