@use '~@sbt-web/ui/common/css/variables';

.close {
  background-color: transparent;
  height: var(--sbt-icon-md);
  fill: var(--sbt-primary-icon-color);
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  z-index: 1;
}
@media (max-width: variables.$tablet_max_width) {
  .modal {
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    z-index: 4242;
    position: fixed;
    visibility: hidden;
    padding: 0 0 2px;
    overflow-y: scroll;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    transform: translate3d(0, 300px, 0);
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    &.isVisible {
      opacity: 1;
      visibility: visible;
      transform: translateZ(0);
    }
  }

  .close-arrow-active {
    .close {
      &.close-arrow {
        display: block;
        left: 16px;
      }
      &.close-x {
        display: none;
      }
    }
  }

  .close {
    top: 13px;
    &.close-arrow {
      display: none;
    }
  }

  .modal-inner {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .modal-sx {
    position: relative;
    .title-cat {
      font-size: 18px;
      line-height: 25px;
      color: var(--sbt-primary-text-color);
      display: block;
      text-align: center;
      margin-bottom: 28px;
    }
  }

  .modal-dx {
    display: flex;
    min-height: 0;
  }

  .modal-dx-inner {
    flex: 1 0 auto;
    overflow: auto;
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-dx-inner > div:first-child .title-list {
    margin-top: 0;
    padding-top: 28px;
  }
}

@media (min-width: variables.$desktop_min_width) {
  .modal {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 10000;
    position: fixed;
    visibility: hidden;
    transition: visibility 0.3s ease, opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    &.isVisible {
      opacity: 1;
      visibility: visible;
      transform: translateZ(0);
      .modal-content {
        opacity: 1;
        visibility: visible;
        transform: scaleX(1);
      }
    }

    .close {
      background-color: transparent;
      height: 24px;
      padding: 0;
      position: absolute;
      top: 24px;
      right: 24px;
      width: 24px;
      z-index: 1;
      &.close-arrow {
        display: none;
      }
    }

    .modal-content {
      width: 780px;
      height: 500px;
      background-color: #fefefe;
      border-radius: 8px;
      z-index: 10001;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;
      transform: scale3d(0.6, 0.6, 1);
      transition: visibility 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
    }

    .modal-inner {
      height: 100%;
      min-height: 100%;
      display: flex;
    }

    .modal-dx {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-basis: calc(100% - 240px);
      border-radius: 0 8px 8px 0;
      padding: var(--sbt-spacing-2xl) var(--sbt-spacing-lg) 0 42px;
      position: relative;
    }
  }
}
